const actions = {
  SET_STATE: 'services/SET_STATE', 
  
  SHOW_UPDATE: 'services/SHOW_UPDATE',
  HIDE_UPDATE: 'services/HIDE_UPDATE',
  SHOW_CREATE: 'services/SHOW_CREATE',
  HIDE_CREATE: 'services/HIDE_CREATE',
  SHOW_POPUP: 'services/SHOW_POPUP',
  HIDE_POPUP: 'services/HIDE_POPUP', 
  SHOW_DETAIL: 'services/SHOW_DETAIL',
  HIDE_DETAIL: 'services/HIDE_DETAIL',
  SHOW_UPLOAD: 'services/SHOW_UPLOAD',
  HIDE_UPLOAD: 'services/HIDE_UPLOAD',
  SHOW_DELETE: 'services/SHOW_DELETE',
  HIDE_DELETE: 'services/HIDE_DELETE',
  REMOVE_SERVICES: 'services/REMOVE_SERVICES',
  SHOW_MODIFY_POPUP: 'services/SHOW_MODIFY_POPUP',
  HIDE_MODIFY_POPUP: 'services/HIDE_MODIFY_POPUP',
  UPDATE_SERVICE_REQUEST: 'services/UPDATE_SERVICE_REQUEST',

  LOAD_ALL: 'services/LOAD_ALL',
  SERVICE_FILTER: 'services/SERVICE_FILTER',
  GET_FILTER_DATES: 'services/GET_FILTER_DATES',
  CREATE_NEWTYRE: 'services/CREATE_NEWTYRE',
  CREATE: 'services/CREATE',
  UPDATE: 'services/UPDATE',
  REMOVE: 'services/REMOVE',
  CLOSE_SERVICE_REQUEST: 'services/CLOSE_SERVICE_REQUEST',
  GET_SERVICE_HISTORY: 'services/GET_SERVICE_HISTORY',
  LOAD_TYRE_SERIALNO: 'services/LOAD_TYRE_SERIALNO',
}

export default actions
