import apiClient from 'services/axios'

const BASE_URL = 'api/driver'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/driver-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
} 

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/add-driver`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(payload) {
  return apiClient
    .post(`${BASE_URL}/edit-driver`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(driverId) {
  return apiClient
    .put(`${BASE_URL}/delete-driver/${driverId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
