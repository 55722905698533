import apiClient from 'services/axios'

const BASE_URL = 'api/services'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/services-list`)
    .then(response => ({ response }))
    .catch(error => {
      console.error('Error loading services list:', error) // Add this line
      return { error }
    })
}

export async function loadAllFilter(payload) {
  return apiClient
    .post(`${BASE_URL}/services-list-date-filter`, payload)
    .then(response => ({ response }))
    .catch(error => {
      console.error('Error loading services list:', error) // Add this line
      return { error }
    })
}

export async function loadfilter(payload) {
  return apiClient
    .post(`${BASE_URL}/services-list-filter/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/add-services`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function newtyre(payload) {
  return apiClient
    .post(`${BASE_URL}/add-services-newtyre`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editServices(payload) {
  return apiClient
    .post(`${BASE_URL}/update-services`, payload)
    .then(response => ({ response }))
    .catch(error => {
      console.error('Error editing services:', error);
      return { error };
    })
}


export async function updateservice(payload) {
  return apiClient
    .post(`${BASE_URL}/update-services`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getservicehistory(payload) {
  return apiClient
    .get(`${BASE_URL}/service-history/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getserviveinfo(payload) {
  return apiClient
    .get(`${BASE_URL}/service-history-detail/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateservicehistory(payload) {
  return apiClient
    .post(`${BASE_URL}/update-services-history`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function removeService(payload) {
  return apiClient
    .put(`${BASE_URL}/delete-service-record/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
