const actions = {
  SET_STATE: 'driver/SET_STATE',
  REMOVE_DATA: 'driver/REMOVE_DATA',
  SHOW_CREATE: 'driver/SHOW_CREATE',
  HIDE_CREATE: 'driver/HIDE_CREATE', 
  SHOW_UPDATE: 'driver/SHOW_UPDATE',
  HIDE_UPDATE: 'driver/HIDE_UPDATE',
  SHOW_DELETE: 'driver/SHOW_DELETE',
  HIDE_DELETE: 'driver/HIDE_DELETE',
  SHOW_UPLOAD: 'driver/SHOW_UPLOAD',
  HIDE_UPLOAD: 'driver/HIDE_UPLOAD',
  LOAD_DRIVER_LIST: 'driver/LOAD_DRIVER_LIST',

  LOAD_ALL: 'driver/LOAD_ALL',
  CREATE: 'driver/CREATE',
  UPDATE: 'driver/UPDATE',
  REMOVE: 'driver/REMOVE',
}

export default actions
