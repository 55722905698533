import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import UserAction from 'redux/user/actions'
import { store as reduxStore } from 'index'

// export const baseUrlInfo = 'http://localhost:3001'
export const baseUrlInfo = 'https://www.production-api.rjschool.in'

// export const baseUrlInfo = 'https://rjschool-backend-api.onrender.com'

const apiClient = axios.create({
  // baseURL: 'http://localhost:3001',
  baseURL: 'https://www.production-api.rjschool.in',
  // baseURL: 'https://rjschool-backend-api.onrender.com',
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin',
    },
  },
})

// apiClient.interceptors.request.use(request => {
//   request.params = request.params || {}
//   const accessToken = store.get('user.accessToken')
//   if (accessToken) {
//     request.headers.Authorization = `Bearer ${accessToken}`
//   }
//   const userId = store.get('user.userId')
//   if (userId) {
//     request.params.userId = userId
//   }

//   return request
// })

// apiClient.interceptors.response.use(
//   response => {
//     if (response?.data?.status === 200) {
//       const { message } = response.data
//       notification.success({
//         message,
//       })
//     } else if (response?.data?.status === 400) {
//       const { message } = response.data
//       notification.error({
//         message,
//       })
//     } else if (response?.data?.status === 401) {
//       const { message } = response.data
//       notification.warning({
//         message,
//       })
//     }
//     return response
//   },
//   error => {
//     if (error?.response?.status === 401) {
//       reduxStore.dispatch({
//         type: UserAction.CLEAR_USER,
//       })
//     } else if (error?.response?.data != null) {
//       const { message } = error.response.data
//       notification.error({
//         message,
//       })
//     }
//     throw error
//   },
// )

// export default apiClient
apiClient.interceptors.request.use(request => {
  request.params = request.params || {}
  const accessToken = store.get('user.accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  const userId = store.get('user.userId')
  if (userId) {
    request.params.userId = userId
  }
  return request
})

apiClient.interceptors.response.use(
  response => {
    if (response?.data?.status === 200) {
      notification.success({ message: response.data.message })
    } else if (response?.data?.status === 400) {
      notification.error({ message: response.data.message })
    } else if (response?.data?.status === 401) {
      notification.warning({ message: response.data.message })
    }
    return response
  },
  error => {
    if (error?.response?.status === 401) {
      reduxStore.dispatch({ type: UserAction.CLEAR_USER })
    } else if (error?.response?.data) {
      notification.error({ message: error.response.data.message })
    }
    throw error
  },
)

export default apiClient
